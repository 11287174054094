import styled from '@emotion/styled';
import * as React from 'react';
import LuvContent from '../components/LuvContent';
import Page from '../components/Page';
import IndexLayout from '../layouts';

const PageTwo = () => {
    // const [zoom, zoomed] = useState(false);
    return (
        <IndexLayout>
            <Page color={'#89CE94'}>
                <LuvContent />
            </Page>
        </IndexLayout>
    );
};

export default PageTwo;

const Gallery = styled.div`
    margin: 3rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    row-gap: 1rem;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const P = styled.p`
    padding-top: 10rem;
`;
